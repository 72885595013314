import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { TrackingPage } from "./routes/tracking";
import { APIProvider } from "@vis.gl/react-google-maps";
import { ShortLinkPage } from "./routes/shortLink";
import { PrivacyPolicyPage } from "./routes/privacy-policy";
import { TermsPage } from "./routes/terms";
import SupportPage from "./routes/support";
import { OpenAppPage } from "./routes/openApp";
import { LandingPage } from "./routes/landing";

function App() {
  const router = createBrowserRouter([
    {
      path: "/privacy-policy",
      element: <PrivacyPolicyPage />,
    },
    {
      path: "/terms",
      element: <TermsPage />,
    },
    {
      path: "/tracking",
      element: <TrackingPage />,
    },
    {
      path: "/support",
      element: <SupportPage />,
    },
    {
      path: "/t/:hash",
      element: <ShortLinkPage />,
    },
    {
      path: "/open-app",
      element: <OpenAppPage />,
    },
    {
      path: "/",
      element: <LandingPage />,
    },
  ]);
  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_API_KEY ?? ""}>
      <RouterProvider router={router} />
    </APIProvider>
  );
}

export default App;
