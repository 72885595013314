import axios from "axios";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const OpenAppPage = () => {
  const location = useLocation();

  const detectMobileOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/android/i.test(userAgent)) {
      return 'android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'ios';
    }
    return 'unknown';
  };

  const redirectToApp = (link: string) => {
    const os = detectMobileOS();
    window.location.href = link;
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const link = params.get('link');
    if (link) {
      redirectToApp(link);
    }
  }, [location]);

  return (
    <div>
      <h2>Redirigiendo...</h2>
    </div>
  );
};
