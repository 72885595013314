import './landing.css';
import logo from "../assets/logo.png"
import appStore from "../assets/app-store.png"
import googlePlay from "../assets/google-play.png"
const GOOGLE_PLAY = "https://play.google.com/store/apps/details?id=com.unbote";
const APP_STORE = "https://apps.apple.com/app/id6738063531";
export const LandingPage = () => {
    const getPlatformLink = (platform: string) => {
        const userAgent = window.navigator.userAgent || window.navigator.vendor;

        if (/iPhone|iPad|iPod/.test(userAgent)) {
            return APP_STORE
        }

        if (/android/i.test(userAgent)) {
            return GOOGLE_PLAY;
        }

        return platform === "apple"
            ? APP_STORE
            : GOOGLE_PLAY;
    };
    return (
        <div className="landing-page">
            <header className="header">
                <img src={logo} alt="Un Bote Logo" className="logo" />
            </header>

            <section className="hero">
                <h1>Un Bote</h1>
                <p>Transporte y Delivery al alcance de tu mano</p>
                <div className="cta-buttons">
                    <a href={getPlatformLink("apple")} className="cta-button apple">
                        <img src={appStore} alt="App Store" />
                    </a>
                    <a href={getPlatformLink("google")} className="cta-button google">
                        <img src={googlePlay} alt="Google Play" />
                    </a>
                </div>
            </section>

            <footer className="footer">
                <p>&copy; 2025 Un Bote. Todos los derechos reservados.</p>
            </footer>
        </div>
    )
};
